import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { initializeApp } from "firebase/app";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);

const firebaseConfig = {
  apiKey: "AIzaSyA4WZ1TPlczZAu6LBEUtZ38RxP6IAxH8oY",
  authDomain: "htien-lounas-dev.firebaseapp.com",
  projectId: "htien-lounas-dev",
  storageBucket: "htien-lounas-dev.appspot.com",
  messagingSenderId: "451780213308",
  appId: "1:451780213308:web:16c503c355080180d0e139",
  measurementId: "G-B0D9SZTMM1",
};

const app = initializeApp(firebaseConfig);
