import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { Navigate, Route, Routes } from "react-router-dom";
import Menu from "./components/menu";

const App = () => {
  const [menu, setMenu] = useState({});

  useEffect(() => {
    onSnapshot(doc(getFirestore(), "lounas", "lounas"), (doc) => {
      const data = doc.data();
      if (data) setMenu(data);
    });
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Menu menu={menu} isScreen={false} />} />
      <Route path="/screen" element={<Menu menu={menu} isScreen={true}/>} />
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};

export default App;
